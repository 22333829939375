.toolbar {
    position: fixed;
    z-index: 2;
    display: flex;
    gap: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    top: 10px;
    left: 10px;
  }
  
  .toolbar input[type="radio"] {
    display: none;
  }
  
  .toolbar label {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .toolbar input[type="radio"]:checked + label {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .actions {
    position: fixed;
    z-index: 2;
    bottom: 10px;
    left: 10px;
    display: flex;
    gap: 10px;
  }
  
  .actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .actions button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .actions button:active {
    transform: scale(1);
  }
  
  .text-area {
    position: fixed;
    top: 200px; /* Adjust as needed */
    left: 100px; /* Adjust as needed */
    font: 24px sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    resize: auto;
    overflow: hidden;
    white-space: pre;
    background: transparent;
    z-index: 2;

  }
  
  
  /* Media query for smaller screens */
  @media screen and (max-width: 600px) {
    .tools {
      flex-direction: column;
      align-items: center;
    }
  
    .tool {
      margin: 5px 0;
    }
  
    .text-area {
      top: unset;
      left: unset;
      width: 90%;
      max-width: 90%;
      margin: 10px auto;
    }
  }
   