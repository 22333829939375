.terms-of-service {
  color: black;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.my-img {
}
@media (max-width: 768px) {
  .my-img {
    width: 30px;
  }
}

.logo {
  max-width: 100px;
  display: flex;
  align-items: center;
  margin: auto;
  padding-top: 30px;
}

.mybg {
  border: 1px solid black;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.067);
}

.btn {
  text-decoration: none;
  padding: 10px;
  color: blueviolet;
  border: 1px solid;
  margin: 20px;
}

.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  width: 50%;
  margin-top: 10px;
  color: #000;
  cursor: pointer;
  background-color: aliceblue;
  text-align: center;
  border: 1px solid;
  padding: 0.18em 0.18em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}

.warning {
  font-family: Arial, sans-serif;
  /* margin: 10px; */
  color: #ff0000; /* Red */
  background-color: #f9dcd5; /* Light pink */
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  max-width: 600px;
  text-align: center;
}
