.like {
  margin: 5rem 0;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    .content {
      background-color: var(--accent-color2);
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 3rem;
      border-radius: 2rem;
      .image {
        img {
        }
      }
      .title {
        color: var(--accent-color1);
      }
      .description {
        color: var(--accent-color3);
        letter-spacing: 0.1rem;
        line-height: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .like {
    margin: 0;
    .container {
      grid-template-columns: 1fr;
      gap: 0;
      .content {
        padding: 1rem 2rem;
        border-radius: 0;
        &:nth-of-type(2) {
          background-color: var(--background);
        }
      }
    }
  }
}
